@import 'tailwindcss/tailwind.css';
@import 'tailwindcss/base';

/* 400 */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/fonts/zYXgKVElMYYaJe8bpLHnCwDKhdHeFaxOedc.woff2) format('woff2');
}
/* 500 */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/fonts/zYX9KVElMYYaJe8bpLHnCwDKjSL9AIFsdP3pBms.woff2) format('woff2');
}
/* 600 */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/fonts/zYX9KVElMYYaJe8bpLHnCwDKjQ76AIFsdP3pBms.woff2) format('woff2');
}
/* 700 */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/fonts/zYX9KVElMYYaJe8bpLHnCwDKjWr7AIFsdP3pBms.woff2) format('woff2');
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  src: url('https://fonts.gstatic.com/s/poppins/v15/pxiByp8kv8JHgFVrLGT9Z1xlEA.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  src: url('https://fonts.gstatic.com/s/poppins/v15/pxiByp8kv8JHgFVrLCz9Z1xlEA.ttf') format('truetype');
}

@layer base {
  html {
    @apply font-IBMPlexSans;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    /* height: 100vh; */
    position: fixed;
    /* overflow: hidden; */
    /* min-height: -webkit-fill-available; */
    /* height: 100vh; */

    overflow: hidden;
    width: 100%;
  }
  body {
    touch-action: pan-x pan-y;
    /* min-height: -webkit-fill-available; */
    overscroll-behavior-y: contain;
    height: 100%;
    width: 100%;
    position: fixed;
    /* height: 100%; */
    /* overflow: hidden; */

    /* prevent overscroll shake*/
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
  }

  body > div {
    height: 100%;
  }

  p {
    -webkit-user-select: none;
  }
  input[type='search'] {
    -moz-appearance: none;
    -webkit-appearance: none;
    border: 0px none transparent;
  }
  /* clears the ‘X’ from Internet Explorer */
  input[type='search']::-ms-clear {
    display: none;
    width: 0;
    height: 0;
  }
  input[type='search']::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
  }
  /* clears the ‘X’ from Chrome */
  input[type='search']::-webkit-search-decoration,
  input[type='search']::-webkit-search-cancel-button,
  input[type='search']::-webkit-search-results-button,
  input[type='search']::-webkit-search-results-decoration {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    display: none;
  }

  .pos_right {
    right: 1.5rem;
  }
  @media (min-width: 480px) {
    .pos_right {
      right: calc(50vw - 240px);
    }
  }
}

@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@layer utilities {
  /* Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .disable-pull-refresh {
    overscroll-behavior: contain;
    overscroll-behavior-y: contain;
  }

  .disable-scroll {
    overflow-y: hidden !important;
  }

  .disable-select {
    -webkit-user-select: none;
  }
}

.closed {
  transform: scale3d(0.5, 0.5, 1) !important;
  opacity: 0 !important;
  visibility: hidden !important;
  z-index: -1;
  /* Dismissal has faster duration. */
  transition: transform 0.2s, opacity 0.2s, visibility 0.2s !important;
  pointer-events: none !important;
  /* position: relative !important; */
}

/* story widget title slide animation */
.slide {
  -webkit-animation: slide-up 1s cubic-bezier(0.65, 0, 0.35, 1) both;
  animation: slide-up 1s cubic-bezier(0.65, 0, 0.35, 1) both;
}

@-webkit-keyframes slide-up {
  0% {
    transform: translateY(100px);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes slide-up {
  0% {
    transform: translateY(100px);
  }
  100% {
    transform: translateY(0);
  }
}
/* story widget image animation */
.storyWidgetImage {
  transform: scale(1.2);
}

.fadein {
  animation: fadein 4s;
}

.fadeout {
  animation: fadeout 4s;
}

@keyframes fadeout {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.shake {
  animation: shake 1s linear infinite;
  animation-delay: 0.45s;
}

@-webkit-keyframes shake {
  10%, 90% {
    -webkit-transform: translate3d(-1px, 0, 0);
            transform: translate3d(-1px, 0, 0);
  }
  20%, 80% {
    -webkit-transform: translate3d(2px, 0, 0);
            transform: translate3d(2px, 0, 0);
  }
  30%, 50%, 70% {
    -webkit-transform: translate3d(-4px, 0, 0);
            transform: translate3d(-4px, 0, 0);
  }
  40%, 60% {
    -webkit-transform: translate3d(4px, 0, 0);
            transform: translate3d(4px, 0, 0);
  }
}

@keyframes shake {
  10%, 90% {
    -webkit-transform: translate3d(-1px, 0, 0);
            transform: translate3d(-1px, 0, 0);
  }
  20%, 80% {
    -webkit-transform: translate3d(2px, 0, 0);
            transform: translate3d(2px, 0, 0);
  }
  30%, 50%, 70% {
    -webkit-transform: translate3d(-4px, 0, 0);
            transform: translate3d(-4px, 0, 0);
  }
  40%, 60% {
    -webkit-transform: translate3d(4px, 0, 0);
            transform: translate3d(4px, 0, 0);
  }
}

/* .scroll-prompt {
  position: absolute;
  z-index: 998;
  bottom: 0;
  left: 50%;
  width: 22px;
  height: 22px;
}

.scroll-prompt-arrow-container {
  position: absolute;
  top: -21px;
  left: 0px;
  margin-left: -12px;
  animation-name: shake;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
}
.scroll-prompt-arrow {
  animation-name: opacity;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
}
.scroll-prompt-arrow:last-child {
  animation-direction: reverse;
  margin-top: -6px;
}
.scroll-prompt-arrow > div {
  width: 12px;
  height: 12px;
  border-right: 2px solid #bebebe;
  border-bottom: 2px solid #bebebe;
  transform: rotate(45deg) translateZ(1px);
} */
